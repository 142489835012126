<template>
  <section id="security">
    <h3 class="heading heading--medium-small mb-2">
      Bezpieczeństwo
    </h3>
    <p class="mb-8">Zmień hasło</p>
    <div class="row mb-48">
      <form @submit.prevent="updatePassword" class="col-12 col-tablet-6">
        <base-input
          v-model="currentPass"
          type="password"
          required
          class="mb-8"
          :error="validationErrors.current_password"
          >obecne hasło</base-input
        >
        <base-input
          v-model="newPass"
          type="password"
          required
          class="mb-8"
          :error="validationErrors.new_password"
          >hasło</base-input
        >
        <base-input
          v-model="newPassRepeat"
          type="password"
          required
          class="mb-8"
          :error="!passwordMatches || validationErrors.new_password"
          >powtórz hasło</base-input
        >
        <base-button
          type="submit"
          :loading="saving"
          :theme="submitType"
          uppercase
          full
          class="mb-4"
        >
          {{ submitText }}
        </base-button>
        <p v-if="validationText" class="color-error">{{ validationText }}</p>
      </form>
    </div>

    <p class="mb-3 heading heading--small">
      Usuwanie konta
    </p>
    <p class="mb-8">
      Uwaga! Wybierając poniższą opcję, spowodujesz trwałe i nieodwracalne usunięcie konta.
    </p>
    <account-security-delete-user />
  </section>
</template>

<script>
import AccountSecurityDeleteUser from './AccountSecurityDeleteUser';

export default {
  components: {
    AccountSecurityDeleteUser,
  },
  data() {
    return {
      currentPass: '',
      newPass: '',
      newPassRepeat: '',
      saving: false,
      savingSuccess: false,
      savingError: false,
      deletionModalOpen: false,
      password: '',
      validationErrors: {},
    };
  },
  computed: {
    passwordMatches() {
      return this.newPass === this.newPassRepeat;
    },
    validationText() {
      if (this.validationErrors.current_password) {
        return 'Niepoprawne obecne hasło!';
      }
      if (this.validationErrors.new_password) {
        return 'Nowe hasło zbyt krótkie lub słabe!';
      }
      return '';
    },
    submitText() {
      if (this.savingSuccess) {
        return 'Hasło zmienione!';
      }
      if (this.savingError) {
        return 'Zmiana się nie udała!';
      }
      return 'Zatwierdź';
    },
    submitType() {
      if (this.savingSuccess) {
        return 'secondary';
      }
      if (this.savingError) {
        return 'error';
      }
      return 'gray-2-plain';
    },
  },
  methods: {
    async updatePassword() {
      this.savingSuccess = false;
      this.validationErrors = {};
      if (!this.passwordMatches) {
        return;
      }
      this.savingError = false;
      this.saving = true;
      try {
        await this.$api.post('auth/users/set_password/', {
          current_password: this.currentPass,
          new_password: this.newPass,
          re_new_password: this.newPassRepeat,
        });
        this.savingSuccess = true;
        this.currentPass = '';
        this.newPass = '';
        this.newPassRepeat = '';

        setTimeout(() => {
          this.savingSuccess = true;
        }, 2000);
      } catch (err) {
        this.savingError = true;
        this.validationErrors = Object.fromEntries(
          Object.keys(err.response.data).map(key => [key, true])
        );
      }
      this.saving = false;
    },
  },
};
</script>
