<template>
  <div
    :class="[
      'range',
      {
        itemsGrow,
        'range--multi': multi,
        'range--single': !multi,
        'range--disabled': disabled,
      },
    ]"
    :style="
      `--base-time-select-options-length: ${options.length}; --base-time-select-options-top: ${before.top}px; --base-time-select-options-left: ${before.left}px; --base-time-select-options-width: ${before.width}px; --base-time-select-options-height: ${before.height}px;`
    "
  >
    <div class="field" v-for="option in preparedOptions" :key="option.id">
      <input
        class="field__input"
        :disabled="disabled"
        :type="inputType"
        :id="option.id"
        :name="option.id"
        :value="option.value"
        :checked="option.checked"
        @input="onChange"
        ref="range-input"
      />
      <label :for="option.id" class="field__label">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: Array,
    itemsGrow: Boolean,
    multi: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      before: {
        top: '0px',
        left: '0px',
        width: 0,
        height: 0,
      },
      render: true,
    };
  },
  computed: {
    inputType() {
      return this.multi ? 'checkbox' : 'radio';
    },
    preparedOptions() {
      return this.options.map(option => {
        const checked = this.multi
          ? this.$attrs.value.includes(option.value)
          : option.value === this.$attrs.value;
        return {
          ...option,
          id: `${this.$attrs.name}-${option.value}`,
          checked,
        };
      });
    },
  },
  watch: {
    '$attrs.value': function(v) {
      this.setBackground();
    },
  },
  mounted() {
    this.setBackground();
    window.addEventListener('resize', this.setBackground);
    // window.addEventListener('orientationchange', event => {
    //   setTimeout(() => {
    //     this.setBackground();
    //   }, 1000);
    // });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBackground);
  },
  methods: {
    onChange(event) {
      if (this.disabled) {
        return;
      }
      const value = Number(event.target.value);
      if (this.multi) {
        let values;
        if (this.$attrs.value.includes(value)) {
          values = this.$attrs.value.filter(v => v !== value);
        } else {
          values = this.$attrs.value.concat([value]);
        }
        this.$emit('input', values);
        return;
      } else {
        this.setBackground();
      }
      this.$emit('input', value);
    },
    setBackground(e) {
      if (this.multi) {
        return;
      }
      setTimeout(() => {
        const active = this.$refs['range-input'].find(
          element => element.checked
        ).parentElement;
        this.before.left = active.offsetLeft;
        this.before.top = active.offsetTop;
        this.before.width = active.offsetWidth;
        this.before.height = active.offsetHeight;
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.range--disabled {
  opacity: 0.5;
  .field {
    &__label {
      cursor: default !important;
    }
  }
}

.range--single {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  @include mq('tablet') {
    grid-gap: 20px;
  }
  border: 2px solid $gray-4;
  border-radius: 5px;
  padding: 3px;
  &:before {
    background-color: $primary;
    position: absolute;
    top: calc(var(--base-time-select-options-top) + 2px);
    left: calc(var(--base-time-select-options-left) + 2px);
    content: '';
    width: calc(var(--base-time-select-options-width) - 4px);
    height: calc(var(--base-time-select-options-height) - 4px);
    transition: all 50ms ease-in-out;
    border-radius: 5px;
  }

  .field {
    width: 100%;
    .field__input {
      opacity: 0;
      position: absolute;
      &:checked + .field__label {
        color: white;
      }
    }
    .field__label {
      position: relative;
      display: block;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      width: 100%;
      padding: 14px 0;
      border-radius: 2px;
    }
  }
}

.range--multi {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  @include mq('tablet') {
    grid-gap: 20px;
  }

  .field {
    width: 100%;
    .field__input {
      opacity: 0;
      position: absolute;
      &:checked + .field__label {
        background-color: $primary;
        color: white;
      }
    }
    .field__label {
      position: relative;
      display: block;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      background-color: $gray-4;
      width: 100%;
      padding: 10px 0;
      border-radius: 2px;
    }
  }
}

.range {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 8px;
  @include mq('tablet') {
    grid-gap: 20px;
  }
}
</style>
