<template>
  <section id="info">
    <h3 class="heading heading--medium-small mb-2">Twoje konto</h3>
    <p class="mb-8">Dane kontaktowe</p>
    <div class="row mb-8">
      <div class="col-12 col-phone-6">
        <base-input
          name="company"
          :value="user.company_name"
          @input="value => updateAccount('company_name', value)"
          class="mb-8"
        >
          nazwa firmy
        </base-input>
        <!--        <base-input-->
        <!--          name="name"-->
        <!--          :value="user.name"-->
        <!--          @input="value => updateAccount('name', value)"-->
        <!--          class="mb-8"-->
        <!--        >-->
        <!--          imię i nazwisko-->
        <!--        </base-input>-->
        <base-input
          name="phone"
          :value="user.phone_number"
          @input="value => updateAccount('phone_number', value)"
          class="mb-8"
        >
          numer telefonu
        </base-input>
      </div>
      <div class="col-12 col-phone-6">
        <base-input
          name="address"
          :value="user.address_street"
          @input="value => updateAccount('address_street', value)"
          class="mb-8"
        >
          adres siedziby
        </base-input>
        <base-input
          name="postcode"
          :value="user.address_postcode"
          @input="value => updateAccount('address_postcode', value)"
          class="mb-8"
        >
          kod pocztowy
        </base-input>
        <base-input
          name="city"
          :value="user.address_city"
          @input="value => updateAccount('address_city', value)"
          class="mb-8"
        >
          miasto
        </base-input>
        <!--        <base-input-->
        <!--          name="vat_number"-->
        <!--          :value="user.vat_number"-->
        <!--          @input="value => updateAccount('vat_number', value)"-->
        <!--          class="mb-8"-->
        <!--        >-->
        <!--          NIP-->
        <!--        </base-input>-->
      </div>
    </div>
    <h3 class="heading heading--medium-small mb-2">Dostępność</h3>
    <p class="mb-8">Zmień adres e-mail</p>
    <div class="row mb-48">
      <form @submit.prevent="updateEmail" class="col-12 col-tablet-6">
        <base-input
          name="email"
          v-model="email"
          required
          class="mb-8"
          :error="errors ? !!errors.new_username : null"
        >
          adres e-mail
        </base-input>
        <base-input
          v-model="password"
          type="password"
          required
          class="mb-8"
          :error="errors ? !!errors.current_password : null"
        >
          obecne hasło
        </base-input>
        <p v-if="errors" class="color-error mb-10">
          <template v-for="error in errors">
            <template v-for="e in error">
              {{ e }}
            </template>
          </template>
        </p>
        <base-button
          type="submit"
          :loading="saving"
          :theme="submitType"
          uppercase
          full
          class="mb-4"
        >
          {{ submitText }}
        </base-button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from '@/util/debounce';

const SAVE_TIMEOUT = 500;

export default {
  data() {
    return {
      email: this.$store.state.user.email,
      password: '',
      emailError: false,
      saving: false,
      savingSuccess: false,
      savingError: false,
      errors: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    submitType() {
      if (this.savingSuccess) {
        return 'secondary';
      }
      if (this.savingError) {
        return 'error';
      }
      return 'gray-2-plain';
    },
    submitText() {
      if (this.savingSuccess) {
        return 'Email zmieniony!';
      }
      if (this.savingError) {
        return 'Zmiana się nie udała!';
      }
      return 'Zatwierdź';
    },
  },
  methods: {
    async updateEmail() {
      if (this.email === this.user.email) {
        this.errors = {
          new_username: ['Nie zmieniono adresu e-mail.'],
        };
        return;
      }
      this.saving = true;
      this.savingError = false;
      this.savingSuccess = false;
      try {
        this.errors = null;
        await this.$store.dispatch('user/updateEmail', {
          email: this.email,
          password: this.password,
        });
        this.savingSuccess = true;
      } catch (err) {
        this.errors = err.response.data;
        this.emailError = true;
        this.savingError = true;
      }
      setTimeout(() => {
        this.savingError = false;
        this.savingSuccess = false;
      }, 4000);
      this.saving = false;
    },
    updateAccount: debounce(function(name, value) {
      this.$store.dispatch('user/updateAccount', {
        [name]: value,
      });
    }, SAVE_TIMEOUT),
  },
};
</script>
