<template>
  <div>
    <h1 class="heading color-primary mb-8">
      moje konto <span class="heading__sub tablet-desktop">vigilex</span>
    </h1>

    <div class="account">
      <nav class="account__nav tablet-desktop">
        <ul class="mb-32">
          <li class="account__nav-item">
            <router-link
              class="account__nav-link"
              :to="`#modules${currentLegislation === 'eu' ? '_eu' : ''}`"
            >
              Twoje moduły
            </router-link>
          </li>
          <li class="account__nav-item">
            <router-link class="account__nav-link" to="#modules-add">
              Dodaj moduły
            </router-link>
          </li>
          <!--          <li class="account__nav-item">-->
          <!--            <router-link class="account__nav-link" to="#modules-personalize">-->
          <!--              Spersonalizuj moduł-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="account__nav-item">
            <router-link class="account__nav-link" to="#reports">
              Raporty email
            </router-link>
          </li>
          <li class="account__nav-item">
            <router-link class="account__nav-link" to="#info">
              Twoje konto
            </router-link>
          </li>
          <li class="account__nav-item">
            <router-link class="account__nav-link" to="#security">
              Bezpieczeństwo
            </router-link>
          </li>
        </ul>

        <base-logout-link />
      </nav>
      <div class="account__main" ref="sections">
        <AccountModules class="mb-32" />
        <AccountConfigurations class="mb-48" />
        <AccountNotifications id="reports" class="mb-48" />
        <AccountInfo class="mb-32" />
        <AccountSecurity class="mb-32" />
      </div>
    </div>
  </div>
</template>

<script>
import AccountInfo from './components/AccountInfo';
import AccountModules from './components/AccountModules';
import AccountConfigurations from './components/AccountConfigurations';
import AccountNotifications from './components/AccountNotifications';
import AccountSecurity from './components/AccountSecurity';

export default {
  components: {
    AccountInfo,
    AccountModules,
    AccountConfigurations,
    AccountNotifications,
    AccountSecurity,
  },
  metaInfo() {
    return {
      title: 'Moje konto',
    };
  },
  computed: {
    currentLegislation: vm => vm.$store.state.currentLegislation,
  },
  mounted() {
    setTimeout(async () => {
      const element = document.getElementById(
        window.location.hash.replace('#', '')
      );

      if (!element) {
        return element;
      }

      const offset = 100;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
      setTimeout(() => {
        this.sectionSwitcher();
      }, 200);
    }, 400);
  },
  methods: {
    sectionSwitcher() {
      const sections = this.$refs.sections.children;

      const intersectionCallback = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const { id } = entry.target;
            this.$router.replace({
              name: 'account',
              hash: `#${id}`,
              params: { noScroll: true },
            });
          }
        });
      };

      const observer = new IntersectionObserver(intersectionCallback, {
        rootMargin: '-50% 0px -50% 0px',
      });

      sections.forEach(section => {
        observer.observe(section);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  @include mq('tablet') {
    display: flex;
    align-items: flex-start;
    padding: 3rem 2rem;
    border: 2px solid $gray-5;
    border-radius: 1.5rem;
  }
  @include mq('desktop') {
    padding: 3.5rem 2.6rem;
  }
}
.account__nav {
  position: sticky;
  top: 100px;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0;
  margin-right: 3rem;

  @include mq('desktop') {
    font-size: 1.14rem;
    padding: 2rem;
    padding-left: 2.5rem;
    margin-right: 4rem;
  }
}
.account__nav-item {
  margin-bottom: 5vh;
}
.account__nav-link {
  position: relative;
  color: $gray-1;

  &::before {
    @include arrowPseudo($primary, 'right');
    position: absolute;
    top: 0.15rem;
    left: -2rem;
    opacity: 0;
    transform: translateX(-0.5rem) rotate(45deg);
    transition: transform 0.2s, opacity 0.2s;
  }

  &.router-link-active {
    color: $primary;

    &::before {
      opacity: 1;
      transform: translateX(0) rotate(45deg);
    }
  }
}
.account__main {
  flex: 1;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 2rem 1rem;
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0.2rem 0.8rem 2.3rem rgba(black, 0.15);
  font-size: 1.14rem;

  @include mq('tablet') {
    margin-left: 0;
    margin-right: 0;
    padding: 2rem 2rem;
  }
  @include mq('desktop') {
    padding: 2rem 3.5rem;
  }
}
</style>
