<template>
  <section id="modules-add">
    <div class="d-flex align-items-center gap-4 gap-tablet-32 mb-12">
      <div>
        <h3 class="heading heading--medium-small mb-2">
          Dodaj nowy moduł
        </h3>
        <div>
          Jeśli nie możesz znaleźć obszaru, którego potrzebujesz – wypełnij ankietę. Na jej podstawie stworzymy nowy moduł tematyczny.
        </div>
      </div>
      <div>
        <base-button
          tag="router-link"
          to="/survey"
          theme="secondary"
          full
          style="white-space: nowrap"
        >
          <template v-if="windowWidth > 768">Wypełnij ankietę</template>
          <template v-else>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5 3.51558V19.5157" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.5 11.5156H20.5001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </base-button>
      </div>
    </div>

    <base-spinner v-if="loading" class="mb-8" />
    <div v-else>
      <p v-if="pollsInProgress.length > 0" class="mb-4">
        Ankiety w edycji
      </p>
      <div v-if="pollsInProgress.length > 0" class="row mb-8">
        <div
          v-for="poll in pollsInProgress"
          :key="poll.pk"
          class="col-12 col-phone-6"
        >
          <router-link :to="`/survey/${poll.pk}`" class="poll-in-progress mb-4">
            {{ poll.related_module ? poll.related_module_details.name_to_display : poll.name }}
            <img src="./icon-edit.svg" alt="" class="poll-pending__icon" />
          </router-link>
        </div>
      </div>

      <p v-if="pollsPending.length > 0" class="mb-4">
        Ankiety oczekujące na autoryzację
      </p>
      <div v-if="pollsPending.length > 0" class="row mb-8">
        <div
          v-for="poll in pollsPending"
          :key="poll.pk"
          class="col-12 col-phone-6"
        >
          <router-link :to="`/survey/${poll.pk}`" class="poll-pending mb-4">
            {{ poll.related_module ? poll.related_module_details.name_to_display : poll.name }}
            <img src="./icon-loading.svg" alt="" class="poll-pending__icon" />
          </router-link>
        </div>
      </div>

      <p v-if="pollsComplete.length > 0" class="mb-8">
        Ankiety zatwierdzone
      </p>
      <div v-if="pollsComplete.length > 0" class="row mb-8">
        <div
          v-for="poll in pollsComplete"
          :key="poll.pk"
          class="col-12 col-phone-6"
        >
          <router-link :to="`/survey/${poll.pk}`" class="poll-accepted mb-4">
            {{ poll.related_module ? poll.related_module_details.name_to_display : poll.name }}
            <img src="./icon-arrow.svg" alt="" class="poll-pending__icon" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      polls: [],
      pollsInProgress: [],
      pollsComplete: [],
      pollsPending: [],
      windowWidth: 0,
    };
  },
  created() {
    this.fetchData();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchData() {
      this.loading = true;
      const { data } = await this.$api.get('/v1/my_polls/', {
        cache: {
          ignoreCache: true,
        },
      });
      const modulesData = await this.$api.get('v1/modules/');
      const modules = modulesData.data.results;
      const polls = data.results.map(poll => {
        // poll.accepted = poll.related_module !== null;
        if (poll.accepted) {
          const possibleModule = modules.find(
            m => m.pk === poll.related_module
          );
          if (possibleModule) {
            poll.name = possibleModule.name_to_display;
            return poll;
          }
        }
        poll.name = `ankieta ${poll.pk}`;
        return poll;
      });
      this.pollsInProgress = polls.filter(
        poll => poll.status === 'in_progress'
      );
      this.pollsPending = polls.filter(poll => poll.status === 'pending');
      this.pollsComplete = polls.filter(poll => poll.status === 'complete');
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.poll-accepted,
.poll-pending,
.poll-in-progress {
  display: block;
  position: relative;
  padding: 1.1rem 4rem 1.1rem 1.1rem;
  border-radius: 0.3rem;
  line-height: 1.2;
  color: white;
}
.poll-accepted__icon {
  position: absolute;
  right: 0.8rem;
  top: 50%;
  margin-top: -0.82rem;
  width: 1.64rem;
  height: 1.64rem;
  border: 2px solid $gray-5;
  border-radius: 0.3rem;
  vertical-align: middle;
  transition: border-color 0.2s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.8rem;
    height: 2px;
    margin-top: -1px;
    background-color: $secondary;
    transform: translateX(-50%) translateY(0) rotate(0) scaleX(0);
    transition: transform 0.15s, background-color 0.2s;
    background-color: $secondary;
  }
  &::before {
    transform: translateX(-75%) translateY(170%) rotate(40deg) scaleX(0.8);
  }
  &::after {
    transform: translateX(-30%) translateY(0) rotate(-65deg) scaleX(1.35);
  }
}
.poll-in-progress {
  border: 2px solid $gray-3;
  color: $gray-1;
}
.poll-accepted {
  background-color: $primary;
}
.poll-pending {
  border: 2px solid $gray-4;
  color: $gray-1;
}
.poll-pending__icon {
  position: absolute;
  top: 50%;
  right: 0.65rem;
  transform: translateY(-50%);
}
</style>
