<template>
  <section :id="`modules${currentLegislation === 'eu' ? '_eu' : ''}`">
    <div id="account-modules-header" class="mb-8">
      <div>
        <h3 class="heading heading--medium-small mb-2">
          Twoje aktywne moduły
        </h3>
        <p>Obszary objęte monitoringiem</p>
      </div>
      <legislation-switch tag="div" @change="legislationChange" />
    </div>
    <base-spinner v-if="loading" class="mb-12" />
    <div v-else class="row mb-12">
      <div
        v-for="module in selectedModules"
        :key="module.id"
        class="col-12 col-phone-6 mb-2 mb-tablet-8"
      >
        <base-checkbox
          :value="true"
          @input="toggleModule(module.id)"
          :name="`module-${module.id}`"
          full
        >
          {{ module.name }}
        </base-checkbox>
      </div>
    </div>
    <section>
      <h3 class="heading heading--medium-small mb-2">
        Nieaktywne moduły
      </h3>
      <p class="mb-8">
        Wybierz obszary, które chcesz jeszcze monitorować
      </p>
      <base-spinner v-if="loading" />
      <div v-else class="row">
        <div
          v-for="module in unselectedModules"
          :key="module.id"
          class="col-12 col-phone-6 mb-2 mb-tablet-8"
        >
          <base-checkbox
            @input="toggleModule(module.id)"
            :name="`module-${module.id}`"
            full
          >
            {{ module.name }}
          </base-checkbox>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import LegislationSwitch from '@/components/LegislationSwitch.vue';
import { mapGetters } from 'vuex';
import legislation from '@/views/Legislation/Legislation.vue';
export default {
  components: {
    LegislationSwitch,
  },
  data() {
    return {
      loading: false,
      modules: [],
      modulesUE: [],
      userId: null,
      dayMonth: '',
      list1: [
        { name: 'Moduł A', id: 1 },
        { name: 'Moduł A', id: 2 },
        { name: 'Moduł A', id: 3 },
        { name: 'Moduł A', id: 4 },
      ],
      list2: [
        { name: 'Moduł B', id: 5 },
        { name: 'Moduł B', id: 6 },
        { name: 'Moduł B', id: 7 },
      ],
      list3: [],
    };
  },
  computed: {
    legislation() {
      return legislation;
    },
    ...mapGetters('user', {
      noUE: 'noUe',
    }),
    currentLegislation: vm => vm.$store.state.currentLegislation,
    selectedModules() {
      return this.modules.filter(module => module.selected);
    },
    unselectedModules() {
      return this.modules.filter(module => !module.selected);
    },
  },
  watch: {
    currentLegislation: {
      handler: function(v) {
        this.fetchModules();
      },
      immediate: true,
    },
  },
  methods: {
    legislationChange(v) {
      if (
        this.$route.hash &&
        v === 'eu' &&
        this.$route.hash !== '#modules_eu'
      ) {
        this.$router.replace({ hash: '#modules_eu' });
      } else if (
        this.$route.hash &&
        v === 'pl' &&
        this.$route.hash !== '#modules'
      ) {
        this.$router.replace({ hash: '#modules' });
      }
    },
    async fetchModules() {
      this.loading = true;
      const modulesData = await this.$api.get(
        `/v1/accessible_modules${
          this.currentLegislation === 'pl' ? '' : '_eu'
        }/`
      );
      const userModules = this.$store.state.user.custom_multi_query;
      const userModulesUE = this.$store.state.user.custom_query_european_union_acts_with_names.map(
        item => item.pk
      );
      this.modules = modulesData.data.results.map(module => ({
        id: module.pk,
        name: module.name_to_display,
        selected:
          this.currentLegislation === 'pl'
            ? userModules.includes(module.pk)
            : userModulesUE.includes(module.pk),
      }));
      this.loading = false;
    },
    async toggleModule(id) {
      this.modules = this.modules.map(module => {
        if (module.id !== id) {
          return module;
        }
        return {
          ...module,
          selected: !module.selected,
        };
      });

      this.$store.dispatch('bookmarks/reset');
      const data = this.modules
        .filter(module => module.selected)
        .map(module => module.id);
      let payload = {};
      if (this.currentLegislation === 'pl') {
        payload.custom_multi_query = data;
      } else if (this.currentLegislation === 'eu') {
        payload.custom_multi_query_eu = data;
      }
      await this.$store.dispatch('user/updateProfile', payload);
    },
  },
};
</script>

<style lang="scss" scoped>
#account-modules-header {
  display: flex;
  align-items: center;
}
</style>
