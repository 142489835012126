<template>
  <section>
    <div class="d-flex">
      <div class="grow">
        <h3 class="heading heading--medium-small mb-2">
          Raporty email
        </h3>
        <p class="mb-8">
          Zdecyduj, w jakiej formie chcesz otrzymywać informacje z
          poszczególnych modułów tematycznych: w osobnych mailach, czy jako
          wyodrębnione sekcje jednego, zbiorczego raportu.
          <br class="desktop" />
        </p>
        <p class="mb-8">
          Możesz też zrezygnować z wysyłki raportów z wybranych modułów.<br
            class="desktop"
          />
        </p>
      </div>
      <!--      <div>-->
      <!--        <legislation-switch tag="div" />-->
      <!--      </div>-->
    </div>
    <template v-if="windowWidth > 768">
      <div class="row mb-32">
        <div class="col-12 col-phone-6">
          <div class="heading heading--small mb-2">
            Osobne raporty
          </div>
        </div>
        <div class="col-12 col-phone-6">
          <div class="heading heading--small mb-2">
            Wspólny raport
          </div>
        </div>
        <div class="col-12 col-phone-6">
          <div class="card card-list h--full pt-0">
            <draggable
              class="list-group"
              :list="singleReports"
              group="people"
              @change="log"
            >
              <div
                :class="['list-group-item mt-4']"
                v-for="(element, index) in singleReports"
                :key="`${element.name}-${index}`"
                @click="excludeFromReport({ index })"
              >
                <base-checkbox full :value="true">
                  {{ element.name_to_display }}
                </base-checkbox>
              </div>
            </draggable>
            <div class="list-group-tip mt-4 text-right">
              <div>
                <small
                  >Odznacz, aby zrezygnować z raportów z wybranego modułu</small
                >
              </div>
              <div>
                <img :src="require('@/assets/images/hand_pointer.svg')" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-phone-6">
          <div class="card card-list h--full pt-0">
            <draggable
              class="list-group"
              :list="multiReportCustomOrdering"
              group="people"
              @change="log"
            >
              <div
                :class="['list-group-item mt-4']"
                v-for="(element, index) in multiReportCustomOrdering"
                :key="`${element.name}-${index}`"
                @click="excludeFromReport({ index, multi: true })"
              >
                <base-checkbox full :value="true">
                  {{ element.name_to_display }}
                </base-checkbox>
              </div>
            </draggable>
            <div class="list-group-tip mt-4">
              <div>
                <img
                  :src="
                    require('@/assets/images/hand_move_vertical_horizontal.svg')
                  "
                />
              </div>
              <div>
                <small>
                  Złap i przeciągnij, aby zmienić kolejność lub przenieść moduł
                  do osobnych raportów.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="heading heading--small mb-2">
            Bez raportów
          </div>
          <div v-if="modulesExcludedFromReport.length === 0" class="mt-4">
            Brak modułów wykluczonych z raportu
          </div>
          <div v-else class="modules-excluded-from-report">
            <div
              v-for="(element, index) in modulesExcludedFromReport"
              :key="`element-${element.pk_reg}`"
              @click="includeToReport(index)"
            >
              <base-checkbox full>
                {{ element.name_to_display }}
              </base-checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <nav
        class="item-nav"
        :class="{
          'item-nav--first-open': currentIndex == 0,
        }"
      >
        <a
          v-for="(tab, i) in tabs"
          :key="`tab-${i}`"
          :class="[
            'item-nav__link',
            {
              'item-nav__link--active': currentIndex == i,
            },
          ]"
          @click="currentIndex = parseInt(i)"
        >
          {{ tab }}
        </a>
      </nav>
      <div class="list-box-select">
        <div
          class="list-box-select--no-modules"
          v-if="!currentList || currentList.length === 0"
        >
          Brak modułów.<br />Zaznacz moduł z innej zakładki aby umieścić go
          tutaj.
        </div>
        <div v-for="(item, i) in currentList" :key="`item-${i}`">
          <div
            :class="[
              'list-box-select--item',
              {
                'list-box-select--disabled':
                  typeof moduleEdition === 'number' && moduleEdition !== i,
              },
            ]"
          >
            {{ item.name_to_display }}
            <div>
              <template v-if="currentIndex === 1">
                <img
                  :src="require('@/assets/icons/ArrowUp.svg')"
                  :style="{
                    opacity: i === 0 || moduleEdition === i ? 0.5 : 1,
                  }"
                  @click="i === 0 || moduleEdition === i ? null : moveUp(i)"
                  :class="{
                    'cursor-pointer': i === 0 || moduleEdition === i,
                  }"
                />
                <img
                  :src="require('@/assets/icons/ArrowDown.svg')"
                  :style="{
                    opacity:
                      i === currentList.length - 1 || moduleEdition === i
                        ? 0.5
                        : 1,
                  }"
                  @click="
                    i === currentList.length - 1 || moduleEdition === i
                      ? null
                      : moveDown(i)
                  "
                  :class="{
                    'cursor-pointer':
                      currentList.length - 1 || moduleEdition === i,
                  }"
                />
              </template>
              <img
                :src="
                  moduleEdition === i
                    ? require('@/assets/icons/ListClose.svg')
                    : require('@/assets/icons/Edit.svg')
                "
                @click="moduleEdition === i ? moduleEdit(null) : moduleEdit(i)"
                class="cursor-pointer"
              />
            </div>
          </div>
          <div v-if="moduleEdition === i" class="text-center mt-12 mb-8">
            <template v-if="moduleMoving === null">
              <div class="heading heading--medium-small mb-2">
                Przenieś wybrany moduł do:
              </div>
              <div class="d-flex justify-center" style="gap: 20px;">
                <base-button
                  v-if="currentIndex <= 1"
                  theme="gray-3-plain"
                  @click="
                    excludeFromReport({
                      index: i,
                      mobile: true,
                      multi: currentIndex === 1,
                    })
                  "
                >
                  Brak
                </base-button>
                <base-button
                  v-if="currentIndex === 2"
                  theme="gray-3-plain"
                  @click="includeToReport(i, true)"
                >
                  Dodaj do raportu
                </base-button>
                <base-button
                  @click="moveToMulti(i)"
                  v-if="currentIndex === 0"
                  theme="secondary"
                >
                  Zbiorczy raport
                </base-button>
                <base-button
                  @click="moveToSingle(i)"
                  v-if="currentIndex === 1"
                  theme="secondary"
                >
                  Osobny raport
                </base-button>
              </div>
            </template>
            <template v-else>
              <div class="heading heading--medium-small mb-2">
                Przeniesiono!
              </div>
              <div class="d-flex justify-center">
                <img :src="require('@/assets/icons/Done.svg')" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>

    <nav
      class="item-nav"
      :class="{
        'item-nav--first-open': frequencyCurrentIndex == 'pl',
      }"
    >
      <a
        v-for="(tab, i) in frequencyTabs"
        :key="`tab-${i}`"
        :class="[
          'item-nav__link',
          {
            'item-nav__link--active': frequencyCurrentIndex == i,
          },
        ]"
        @click="frequencyCurrentIndex = i"
      >
        {{ tab }}
      </a>
    </nav>

    <div class="frequency-section">
      <h4 class="heading heading--small heading--switch mb-1">
        Raporty cykliczne
        <base-switch
          v-model="reportsFrequencyEnabled"
          class="ml-2"
          name="reportsFrequencyEnabled"
          small
          @input="updateReportsFrequencyEnabled"
        />
      </h4>
      <div class="mb-2">
        Wybierz dni tygodnia, w które otrzymasz raporty z monitoringu.
      </div>
      <range-input
        name="reportsFrequency"
        :value="reportsFrequency"
        @input="updateReportsFrequency"
        :disabled="!reportsFrequencyEnabled"
        :options="[
          { value: 0, label: 'Pon.' },
          { value: 1, label: 'Wt.' },
          { value: 2, label: 'Śr.' },
          { value: 3, label: 'Czw.' },
          { value: 4, label: 'Pt.' },
          { value: 5, label: 'Sob.' },
          { value: 6, label: 'Ndz.' },
        ]"
        multi
        class="range-input mb-12"
      />

      <h4 class="heading heading--small heading--switch mb-1">
        Raport podsumowujący
        <base-switch
          v-model="summaryFrequencyEnabled"
          class="ml-2"
          name="summaryFrequencyEnabled"
          small
          @input="updateSummaryFrequencyEnabled"
        />
      </h4>
      <div class="mb-2">
        Wybierz dzień, w którym otrzymasz raport podsumowujący.
      </div>
      <range-input
        name="summary"
        :value="getSummaryFrequency()"
        @input="updateSummaryFrequency"
        :disabled="!summaryFrequencyEnabled"
        :options="[
          { value: 1, label: 'Co tydzień' },
          { value: 2, label: 'Co 2 tygodnie' },
          { value: 4, label: 'Co miesiąc' },
        ]"
        class="range-input mb-8"
      />
      <range-input
        v-if="getSummaryFrequency() !== 4"
        name="summaryDay"
        :value="getWeeklySummaryDay()"
        @input="updateWeeklySummaryDay"
        :disabled="!summaryFrequencyEnabled"
        :options="[
          { value: 1, label: 'Pon.' },
          { value: 2, label: 'Wt.' },
          { value: 3, label: 'Śr.' },
          { value: 4, label: 'Czw.' },
          { value: 5, label: 'Pt.' },
          { value: 6, label: 'Sob.' },
          { value: 7, label: 'Niedz.' },
        ]"
        class="range-input mb-8"
      />
      <div
        v-else
        :class="[
          'month-day mb-8',
          {
            'month-day--disabled': !summaryFrequencyEnabled,
          },
        ]"
      >
        <base-input-plain
          type="text"
          min="1"
          max="31"
          placeholder="Podaj dzień miesiąca (od 1 do 31)"
          :value="getMonthlySummaryDay()"
          :disabled="!summaryFrequencyEnabled"
          @input="updateMonthlySummaryDay"
          class="month-day-input"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RangeInput from './RangeInput';
import draggable from 'vuedraggable';
import { api } from '@/api/api';

export default {
  components: {
    RangeInput,
    draggable,
  },
  data() {
    return {
      reportsFrequencyEnabled: true,
      summaryFrequencyEnabled: true,
      loading: false,
      modules: [],
      modulesUE: [],
      userId: null,
      dayMonth: '',
      singleReports: [],
      multiReportCustomOrdering: [],
      multiReportCustomOrderingWatcher: null,
      modulesExcludedFromReport: [],
      windowWidth: 0,
      currentIndex: 0,
      tabs: {
        0: 'osobne',
        1: 'zbiorczy',
        2: 'brak',
      },
      frequencyCurrentIndex: 'pl',
      frequencyTabs: {
        pl: 'legislacja PL',
        eu: 'legislacja UE',
        news: 'aktualności',
      },
      report: null,
      moduleEdition: null,
      moduleMoving: null,
      renderComponents: true,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    reportsFrequency() {
      let reportsFrequency;
      if (this.frequencyCurrentIndex === 'pl') {
        reportsFrequency = this.user.reports_frequency;
      } else if (this.frequencyCurrentIndex === 'eu') {
        reportsFrequency = this.user.reports_frequency_eu;
      } else if (this.frequencyCurrentIndex === 'news') {
        reportsFrequency = this.user.reports_frequency_news;
      }
      const data = JSON.parse(reportsFrequency);
      if (Array.isArray(data)) {
        return data;
      } else {
        return [];
      }
    },
    currentList: function() {
      switch (true) {
        case this.currentIndex === 0:
          return this.singleReports;
        case this.currentIndex === 1:
          return this.multiReportCustomOrdering;
        default:
          return this.modulesExcludedFromReport;
      }
    },
  },
  watch: {
    currentList: {
      handler: function() {
        this.moduleEdition = null;
      },
      deep: true,
    },
    user: {
      immediate: true,
      handler: function() {
        this.setReportLists();
      },
    },
    frequencyCurrentIndex: {
      immediate: true,
      handler: function(index) {
        this.renderComponents = false;
        if (index === 'pl') {
          this.reportsFrequencyEnabled = this.user.reports_enabled;
          this.summaryFrequencyEnabled = this.user.summary_enabled;
        } else if (index === 'eu') {
          this.reportsFrequencyEnabled = this.user.reports_enabled_eu;
          this.summaryFrequencyEnabled = this.user.summary_enabled_eu;
        } else if (index === 'news') {
          this.reportsFrequencyEnabled = this.user.reports_enabled_news;
          this.summaryFrequencyEnabled = this.user.summary_enabled_news;
        }
        this.$nextTick(() => {
          this.renderComponents = true;
        });
      },
    },
  },
  async created() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    const response = await api.get('v1/reports/');
    let report = response.data.results[0];
    if (!report.modules_excluded_from_report) {
      report.modules_excluded_from_report = [];
    } else {
      report.modules_excluded_from_report = JSON.parse(
        report.modules_excluded_from_report
      );
    }
    if (!report.multi_report_custom_ordering) {
      report.multi_report_custom_ordering = [];
    } else {
      report.multi_report_custom_ordering = JSON.parse(
        report.multi_report_custom_ordering
      );
    }
    this.report = report;
    this.setReportLists();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions('user', ['getUserProfile']),
    moveToMulti(i) {
      this.multiReportCustomOrdering.push(this.singleReports[i]);
      this.moduleMoving = i;
      setTimeout(() => {
        this.moduleMoving = null;
        this.singleReports.splice(i, 1);
      }, 1500);
    },
    moveToSingle(i) {
      this.singleReports.push(this.multiReportCustomOrdering[i]);
      this.moduleMoving = i;
      setTimeout(() => {
        this.moduleMoving = null;
        this.multiReportCustomOrdering.splice(i, 1);
      }, 1500);
    },
    moduleEdit(index) {
      this.moduleEdition = index;
    },
    moveUp(index) {
      const module = this.multiReportCustomOrdering[index];
      this.multiReportCustomOrdering.splice(index, 1);
      this.multiReportCustomOrdering.splice(index - 1, 0, module);
      this.sendReport();
    },
    moveDown(index) {
      const module = this.multiReportCustomOrdering[index];
      this.multiReportCustomOrdering.splice(index, 1);
      this.multiReportCustomOrdering.splice(index + 1, 0, module);
      this.sendReport();
    },
    excludeFromReport({ index, mobile = false, multi = false }) {
      const module = multi
        ? this.multiReportCustomOrdering[index]
        : this.singleReports[index];
      this.modulesExcludedFromReport.push(module);
      this.sendReport();
      this.moduleMoving = index;
      setTimeout(
        () => {
          this.moduleMoving = null;
          if (multi) {
            this.multiReportCustomOrdering.splice(index, 1);
          } else {
            this.singleReports.splice(index, 1);
          }
        },
        mobile ? 1500 : 0
      );
    },
    includeToReport(index, mobile = false) {
      const module = this.modulesExcludedFromReport[index];
      this.moduleMoving = index;
      setTimeout(
        () => {
          this.singleReports.push(module);
          this.modulesExcludedFromReport.splice(index, 1);
          this.sendReport();
          this.moduleMoving = null;
        },
        mobile ? 1500 : 0
      );
    },
    setReportLists() {
      if (!this.user || !this.report) {
        return [];
      }
      const usersModules = this.user.modules;
      const multiReportCustomOrdering = usersModules.filter(
        obj =>
          this.user.custom_multi_query_reg.includes(obj.pk_reg) &&
          this.report.multi_report_custom_ordering.includes(obj.pk_reg)
      );
      this.multiReportCustomOrdering = multiReportCustomOrdering.sort(
        (a, b) => {
          return (
            this.report.multi_report_custom_ordering.indexOf(a.pk_reg) -
            this.report.multi_report_custom_ordering.indexOf(b.pk_reg)
          );
        }
      );
      const modulesExcludedFromReport = usersModules.filter(
        obj =>
          this.user.custom_multi_query_reg.includes(obj.pk_reg) &&
          this.report.modules_excluded_from_report.includes(obj.pk_reg)
      );
      this.modulesExcludedFromReport = modulesExcludedFromReport;
      if (!this.multiReportCustomOrderingWatcher) {
        this.multiReportCustomOrderingWatcher = this.$watch(
          'multiReportCustomOrdering',
          {
            deep: true,
            handler: async function() {
              this.sendReport();
            },
          }
        );
      }
      const singleReports = usersModules.filter(obj => {
        return (
          this.user.custom_multi_query_reg.includes(obj.pk_reg) &&
          !this.report.multi_report_custom_ordering.includes(obj.pk_reg) &&
          !this.report.modules_excluded_from_report.includes(obj.pk_reg)
        );
      });
      this.singleReports = singleReports;
    },
    async sendReport() {
      let multi_report_custom_ordering = [];
      let modules_excluded_from_report = [];
      for (let i in this.multiReportCustomOrdering) {
        let item = this.multiReportCustomOrdering[i];
        multi_report_custom_ordering.push(item.pk_reg);
      }
      for (let i in this.modulesExcludedFromReport) {
        let item = this.modulesExcludedFromReport[i];
        modules_excluded_from_report.push(item.pk_reg);
      }
      let report = JSON.parse(JSON.stringify(this.report));
      delete report.pk;
      report.modules_excluded_from_report = JSON.stringify(
        modules_excluded_from_report
      );
      report.multi_report_custom_ordering = JSON.stringify(
        multi_report_custom_ordering
      );
      await api.put(`v1/reports/${this.report.pk}/`, report);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    updateMonthlySummaryDay(value) {
      let name = 'monthly_summary_day';
      if (this.frequencyCurrentIndex === 'eu') {
        name += '_eu';
      } else if (this.frequencyCurrentIndex === 'news') {
        name += '_news';
      }
      if (value) {
        this.update(name, Math.max(1, Math.min(29, value)));
      }
    },
    getMonthlySummaryDay() {
      if (this.frequencyCurrentIndex === 'pl') {
        return this.user.monthly_summary_day || 1;
      } else if (this.frequencyCurrentIndex === 'eu') {
        return this.user.monthly_summary_day_eu || 1;
      } else if (this.frequencyCurrentIndex === 'news') {
        return this.user.monthly_summary_day_news || 1;
      }
    },
    getWeeklySummaryDay() {
      if (this.frequencyCurrentIndex === 'pl') {
        return this.user.weekly_summary_day;
      } else if (this.frequencyCurrentIndex === 'eu') {
        return this.user.weekly_summary_day_eu;
      } else if (this.frequencyCurrentIndex === 'news') {
        return this.user.weekly_summary_day_news;
      }
    },
    updateWeeklySummaryDay(value) {
      let name = 'weekly_summary_day';
      if (this.frequencyCurrentIndex === 'eu') {
        name += '_eu';
      } else if (this.frequencyCurrentIndex === 'news') {
        name += '_news';
      }
      this.update(name, value);
    },
    updateSummaryFrequency(value) {
      let name = 'summary_frequency';
      if (this.frequencyCurrentIndex === 'eu') {
        name += '_eu';
      } else if (this.frequencyCurrentIndex === 'news') {
        name += '_news';
      }
      this.update(name, value);
    },
    async updateReportsFrequencyEnabled(value) {
      let url;
      if (this.frequencyCurrentIndex === 'pl') {
        url = `v0/reports/toggle_reports_enabled/${this.report.pk}`;
      } else if (this.frequencyCurrentIndex === 'eu') {
        url = `v0/reports/toggle_reports_eu_enabled/${this.report.pk}`;
      } else if (this.frequencyCurrentIndex === 'news') {
        url = `v0/reports/toggle_reports_news_enabled/${this.report.pk}`;
      }
      try {
        const response = value ? await api.put(url) : await api.delete(url);
        if (response.status <= 201) {
          this.getUserProfile();
        }
      } catch (err) {
        const response = err.response;
        if (response.status === 403) {
          this.reportsFrequencyEnabled = false;
          this.$store.commit('toggleReportModal', this.frequencyCurrentIndex);
        }
      }
    },
    async updateSummaryFrequencyEnabled(value) {
      let url;
      if (this.frequencyCurrentIndex === 'pl') {
        url = `v0/reports/toggle_summary_enabled/${this.report.pk}`;
      } else if (this.frequencyCurrentIndex === 'eu') {
        url = `v0/reports/toggle_summary_eu_enabled/${this.report.pk}`;
      } else if (this.frequencyCurrentIndex === 'news') {
        url = `v0/reports/toggle_summary_news_enabled/${this.report.pk}`;
      }
      try {
        const response = value ? await api.put(url) : await api.delete(url);
        if (response.status <= 201) {
          this.getUserProfile();
        }
      } catch (err) {
        const response = err.response;
        if (response.status === 403) {
          this.summaryFrequencyEnabled = false;
          this.$store.commit('toggleReportModal', this.frequencyCurrentIndex);
        }
      }
    },
    update(name, value) {
      this.$store.dispatch('user/updateProfile', {
        [name]: value,
      });
    },
    getSummaryFrequency() {
      if (this.frequencyCurrentIndex === 'pl') {
        return this.user.summary_frequency;
      } else if (this.frequencyCurrentIndex === 'eu') {
        return this.user.summary_frequency_eu;
      } else if (this.frequencyCurrentIndex === 'news') {
        return this.user.summary_frequency_news;
      }
    },
    updateReportsFrequency(values) {
      let data = {};
      if (this.frequencyCurrentIndex === 'pl') {
        data.reports_frequency = JSON.stringify(values.sort());
      } else if (this.frequencyCurrentIndex === 'eu') {
        data.reports_frequency_eu = JSON.stringify(values.sort());
      } else if (this.frequencyCurrentIndex === 'news') {
        data.reports_frequency_news = JSON.stringify(values.sort());
      }
      this.$store.dispatch('user/updateProfile', data);
    },
    add: function() {
      this.list.push({ name: 'Juan' });
    },
    replace: function() {
      this.list = [{ name: 'Edgard' }];
    },
    clone: function(el) {
      return {
        name: el.name + ' cloned',
      };
    },
    log: function(evt) {
      // window.console.log(evt);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-input {
  max-width: 30rem;
}
.month-day {
  max-width: 30rem;
  display: flex;
  align-items: center;
  min-height: 58px;
  &--disabled {
    opacity: 0.5;
  }
}
.month-day-text {
  font-size: 1rem;
  padding-right: 1rem;
}
.month-day-input {
  width: 30%;
  padding: 0.7rem 1rem;
  height: 58px;
  border-color: $gray-4;

  @include mq('phone') {
    margin-right: 1rem;
    width: 68%;
  }
}

.item-nav {
  z-index: 10;
  margin-inline: -14px;
  position: relative;
  //display: flex;
  //background-color: $gray-6;
  list-style: none;
  //padding: 0.3rem;
  //padding-right: 0;
  //border-radius: 0.4rem;

  //@include mq('tablet') {
  border-radius: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  background-color: transparent;
  // box-shadow: inset 0 -10px 10px -10px #D4D4D4;
  //box-shadow: 10px 10px 20px #D4D4D4;
  &::v-deep .legislation-switches {
    align-items: center;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: white;
  }
  &::before {
    z-index: 1;
    height: 66px;
  }
  &::after {
    box-shadow: 0px 10px 30px #d4d4d4;
    z-index: -1;
    height: 18px;
  }
  &--first-open {
    &::before,
    &::after {
      border-top-left-radius: 0;
    }
  }
  //}
}

.item-nav__link {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  display: inline-block;
  color: $gray-3;
  padding: 0.5rem 1rem;
  line-height: 1;
  cursor: pointer;

  &:first-child {
    &::after {
      display: none !important;
    }
  }
  border-radius: 0;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  transition: border-bottom-color 0.15s, color 0.15s;
  padding-inline: 13px;
  padding-block: 10px;
  &.router-link-active,
  &--active {
    background-color: #ffffff;
    color: $primary;
    box-shadow: 0px 0px 20px #d4d4d4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    & + .item-nav__link {
      &::after {
        display: none;
      }
    }
    &::before,
    &::after {
      all: unset;
      content: '';
      position: absolute;
      bottom: 0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      z-index: 1;
      opacity: 1;
    }
    &::before {
      left: 100%;
      box-shadow: -10px 10px 0px 0px #ffffff;
    }
    &::after {
      right: 100%;
      box-shadow: 10px 10px 0px 0px #ffffff;
    }
  }
  &:hover {
    color: $primary;
  }

  @include mq('desktop') {
    font-size: 1.14rem;
  }
}

.item-nav__more {
  padding-right: 2rem;
}
.item-nav__more--open {
  background-color: $primary;
  color: white;

  &::before {
    border-color: white;
  }
}
.item-nav__more-list {
  display: none;
  position: absolute;
  margin-top: 1rem;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0.5rem;
  padding-bottom: 0;
  background-color: $gray-6;
  border-radius: 0.4rem;
  box-shadow: 0 0.8rem 1rem rgba(black, 0.1);

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    margin-bottom: -1px;
    right: 2.5rem;
    border: 0.8rem solid transparent;
    border-bottom-width: 0.6rem;
    border-bottom-color: $gray-6;
  }
}
.item-nav__more-list--open {
  display: block;
}
.item-nav__more-link {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  background-color: white;
  text-align: center;

  &.router-link-active {
    background-color: $primary;
    color: white;
  }
}

.list-box-select {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  gap: 10px;
  margin-bottom: 25px;
  &--no-modules {
    font-size: 12px;
  }
  &--disabled {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      z-index: 10;
      background-color: white;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.5;
    }
  }
  &--item {
    background-color: $primary;
    color: white;
    border-radius: 0.4rem;
    min-height: 50px;
    padding-inline: 10px;
    padding-block: 3px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      grid-gap: 3px;
    }
  }
}

.modules-excluded-from-report {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.list-group-tip {
  display: grid;
  align-items: center;
  grid-gap: 20px;
  grid-template-columns: 45px 1fr;
  color: $gray-1;
  &.text-right {
    grid-template-columns: 1fr 45px;
  }
}

.card.card-list {
  display: flex;
  flex-direction: column;
  .list-group {
    flex-grow: 1;
  }
}

.frequency-section {
  margin-top: 30px;
  position: relative;
  z-index: 10;
}
</style>
