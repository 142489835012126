<template>
  <div>
    <button
      @click="deletionModalOpen = true"
      class="remove-account color-error"
    >
      Usuń konto
    </button>

    <base-modal
      :open="deletionModalOpen"
      @close="closeModal"
      theme="mobileCloseX"
    >
      <div v-if="!deleted" class="delete-user-modal">
        <h3 class="heading--medium-small mb-4 color-error">
          Uwaga! Usuwanie konta użytkownika
        </h3>
        <p class="mb-12">
          Tak, potwierdzam usunięcie mojego konta i rozumiem że zostanie one
          bezpoworotnie skasowane.
        </p>
        <form @submit.prevent="deleteUser" class="deletion-form mb-16">
          <base-input
            v-model="password"
            type="password"
            class="mb-8"
            :error="wrongPassword"
            @focus="wrongPassword = false"
            required
          >
            Twoje hasło
          </base-input>
          <base-button
            type="submit"
            theme="error"
            :loading="deleting"
            plain
            full
            >Potwierdzam</base-button
          >
        </form>
      </div>
      <div v-else class="delete-user-modal">
        <h3 class="heading--medium-small mb-4 color-primary">
          Twoje konto zostało skasowane.<br />
          Szkoda że się z nami rozstajesz.
        </h3>
        <div v-if="!sentFeedback" class="mb-24">
          <p class="mb-8">
            Jeśli chciałbyś podzielić się z nami opinią na temat serwisu lub
            wyjawić nam przyczyny decyzji o usunięcie konta, pozostaw nam proszę
            wiadomość.
          </p>
          <form @submit.prevent="sendFeedback" class="feedback-form">
            <base-textarea
              v-model="feedback"
              placeholder="Przyczyna rezygnacji lub komentarz dotyczący serwisu…"
              rows="8"
              class="mb-4"
              required
            />
            <base-button
              type="submit"
              theme="secondary"
              :loading="sendingFeedback"
              plain
              wide
              >Wyślij</base-button
            >
          </form>
        </div>
        <div v-else class="mb-24">
          <p>
            Wiadomość wysłana, dziękujemy!
          </p>
        </div>

        <p class="mb-8">
          Jeśli chciałbyś założyć nowe konto, naciśnij poniższy przycisk.
        </p>
        <base-button @click="newAccount" theme="secondary" uppercase wide
          >Załóż konto</base-button
        >
      </div>
    </base-modal>
  </div>
</template>

<script>
import { removeApiToken } from '../../../api/token';

export default {
  data() {
    return {
      deletionModalOpen: false,
      password: '',
      wrongPassword: false,
      deleting: false,
      deleted: false,
      feedback: '',
      sendingFeedback: false,
      sentFeedback: false,
    };
  },
  methods: {
    async deleteUser() {
      this.deleting = true;
      try {
        await this.$api.post('/auth/jwt/create', {
          username: this.$store.state.user.username,
          password: this.password,
        });
      } catch (error) {
        this.wrongPassword = true;
        this.deleting = false;
        return;
      }
      this.$api.delete('v0/delete_user');
      removeApiToken();
      this.deleted = true;
      this.deleting = false;
    },
    async sendFeedback() {
      this.sendingFeedback = true;
      await this.$api.post('v1/send_message/', {
        email: this.$store.state.user.username,
        content: this.feedback,
      });
      this.feedback = '';
      this.sentFeedback = true;
      this.sendingFeedback = false;
    },
    newAccount() {
      this.$router.push('/signup');
      this.$nextTick(() => {
        this.$store.dispatch('user/logout');
      });
    },
    closeModal() {
      if (this.deleted) {
        this.$store.dispatch('user/logout');
      }
      this.deletionModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-user-modal {
  max-width: 52rem;

  @include mq('tablet') {
    font-size: 1.14rem;
  }
}
.remove-account {
  font-size: 1.14rem;
  border-bottom: 1px solid $error;
}
.deletion-form {
  max-width: 20rem;
}

.feedback-form {
  max-width: 35rem;
}
</style>
