var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'range',
    {
      itemsGrow: _vm.itemsGrow,
      'range--multi': _vm.multi,
      'range--single': !_vm.multi,
      'range--disabled': _vm.disabled,
    },
  ],style:(`--base-time-select-options-length: ${_vm.options.length}; --base-time-select-options-top: ${_vm.before.top}px; --base-time-select-options-left: ${_vm.before.left}px; --base-time-select-options-width: ${_vm.before.width}px; --base-time-select-options-height: ${_vm.before.height}px;`)},_vm._l((_vm.preparedOptions),function(option){return _c('div',{key:option.id,staticClass:"field"},[_c('input',{ref:"range-input",refInFor:true,staticClass:"field__input",attrs:{"disabled":_vm.disabled,"type":_vm.inputType,"id":option.id,"name":option.id},domProps:{"value":option.value,"checked":option.checked},on:{"input":_vm.onChange}}),_vm._v(" "),_c('label',{staticClass:"field__label",attrs:{"for":option.id}},[_vm._v("\n      "+_vm._s(option.label)+"\n    ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }